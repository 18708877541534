<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách phiếu giữ hàng'">
          <template v-slot:toolbar>
            <div class="row">
              <div>
                <b-dropdown size="sm" id="dropdown-1" right>
                  <template slot="button-content">
                    <i style="font-size: 1rem" class="fas fa-cog"></i>
                    <span class="font-weight-bolder">Thao tác</span>
                  </template>
                  <b-dropdown-item @click="reportClick">
                    <span>
                      <i style="font-size: 1rem" class="far fa-file-excel"></i>
                      &nbsp; Xuất Excel</span
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <b-row mb="5" v-if="warningOverHoldingTime">
                <b-col md="12">
                    <p class="text-danger">
                        <span>&#42;</span> Bạn có <span class="hold-report">{{totalNewHoldingLast24Hours}}</span> phiếu giữ hàng mới 
                            vượt quá <span class="hold-report">24h</span>, và <span class="hold-report">{{totalNewHoldingLast48Hours}}</span> 
                            phiếu giữ hàng mới vượt quá <span class="hold-report">48h</span>.
                    </p>
                </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="2">
                <b-form-select
                  id="input-3"
                  size="sm"
                  v-model="selectedCompany"
                  :options="listCompany"
                  required
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  v-on:change="onChangeCompany"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >-- Doanh nghiệp --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-col>
              <b-col md="3">
                <multiselect
                  v-model="valueStore"
                  :options="optionsStore"
                  :multiple="true"
                  track-by="code"
                  :close-on-select="false"
                  @select="onSelectStore($event)"
                  @remove="onRemoveStore($event)"
                  :show-labels="false"
                  :showNoResults="false"
                  :showPointer="false"
                  :custom-label="customLabelStore"
                  placeholder="Chọn cửa hàng"
                >
                  <template slot="tag">{{ '' }}</template>
                  <template
                    slot="selection"
                    slot-scope="{ values, isOpen }"
                    ><span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                      >{{ valueStore.length }} cửa hàng đã chọn</span
                    ></template
                  >
                  <template v-slot:no-result>
                    Danh sách cửa hàng rỗng!
                  </template>
                  <span
                    class="checkbox-label"
                    slot="option"
                    slot-scope="scope"
                    @click.self="select(scope.option)"
                  >
                    {{ scope.option.name }}
                    <input
                      class="test"
                      type="checkbox"
                      v-model="scope.option.checked"
                      @focus.prevent
                    />
                  </span>
                  <span
                    class="checkbox-label"
                    style="font-size: 12px"
                    slot="noResult"
                  >
                    Không có kết quả
                  </span>
                </multiselect>
              </b-col>
              <b-col md="3">
                <vue-autosuggest
                  :suggestions="filteredOptionsCustomer"
                  @selected="onSelectedCustomer"
                  :limit="10"
                  @input="onInputChangCustomer"
                  :input-props="inputSearchCustomerProp"
                  :get-suggestion-value="getSuggestionValue"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="searchCustomer"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    {{ suggestion.item.suggestionName }}
                  </div>
                </vue-autosuggest>
              </b-col>
              <b-col md="2">
                <b-form-input
                  v-model="searchImeiCode"
                  type="text"
                  placeholder="Mã IMEI"
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                ></b-form-input>
              </b-col>
              <b-col>
                <b-form-select
                  size="sm"
                  v-model="selectedStatus"
                  :options="listStatus"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="2">
                <b-form-input
                  v-model="searchId"
                  type="text"
                  placeholder="Mã phiếu"
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                ></b-form-input>
              </b-col>
              <b-col md="3">
                <b-form-input
                  v-model="search"
                  type="text"
                  placeholder="Tìm kiếm theo nội dung"
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                ></b-form-input>
              </b-col>
              <b-col md="3">
                <vue-autosuggest
                  v-model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  @selected="onSelectedProduct"
                  :limit="10"
                  @input="onInputChangeProduct"
                  :get-suggestion-value="getSuggestionValue"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Nhập mã, tên, mã vạch sản phẩm',
                  }"
                  :should-render-suggestions="
                    (size, loading) =>
                      size >= 0 && !loading && searchProduct !== ''
                  "
                  ><div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    {{ suggestion.item.productName }}
                  </div>
                </vue-autosuggest>
              </b-col>
              <b-col md="2">
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control-sm"
                  :config="dpConfigs.start"
                  v-model="dpForm.startDate"
                ></date-picker>
              </b-col>
              <b-col md="2">
                <date-picker
                  placeholder="Đến ngày"
                  class="form-control-sm"
                  :config="dpConfigs.end"
                  v-model="dpForm.endDate"
                ></date-picker>
              </b-col>
            </b-row>
            <b-row class="mb-5">
                <b-col md="2">
                    <b-form-select
                        id="input-4"
                        size="sm"
                        v-model="selectedQuickFilterTime"
                        :options="listQuickFilterTime"
                        v-on:change="onQuickFilterChange"
                    >
                        <template v-slot:first>
                            <b-form-select-option :value="null">
                                -- Chọn nhanh khung thời gian --
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col md="10"></b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="stocks"
              :fields="fields"
              bordered
              hover
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>

              <template v-slot:cell(count)="row">
                <div style="text-align: center">
                  <span v-text="row.item.count" class="text-right"></span>
                </div>
              </template>

              <template v-slot:cell(code)="row">
                <div class="productCode" style="cursor: pointer">
                  <a
                    class="font-weight-bold"
                    style="color: black"
                    v-bind:href="getLinkDoc(row.item)"
                    target="_blank"
                  >
                    {{ row.item.code }}
                  </a>
                </div>
              </template>

              <template v-slot:cell(customerPhone)="row">
                <div>
                  <p
                    class="productCode"
                    @click="editUser(row.item)"
                    style="margin-bottom: 0; cursor: pointer; font-weight: 550"
                  >
                    {{ row.item.customerName }}
                  </p>
                  <p
                    class="productCode"
                    @click="editUser(row.item)"
                    style="margin-bottom: 0; cursor: pointer"
                  >
                    {{ row.item.customerPhone }}
                  </p>
                </div>
              </template>

              <template v-slot:cell(createdAt)="row">
                <div style="text-align: left">
                  <span
                    v-text="row.item.createdAt"
                    class="text-left text-primary mb-0"
                  ></span>
                  <p class="text-left">{{ row.item.createBy }}</p>
                </div>
              </template>
              <template v-slot:cell(sourceWarehouseId)="row">
                <span
                  v-text="row.item.sourceWarehouseId"
                  class="font-weight-bolder"
                ></span>
              </template>
              <template v-slot:cell(description)="row">
                <div
                  @mouseover="hoverPrice = true"
                  @mouseleave="hoverPrice = false"
                >
                  <span v-text="row.item.description" class="mr-2"></span>
                  <span @click="handleShowNoteModel(row.item)">
                    <i v-if="hoverPrice" class="fas fa-edit"></i>
                  </span>
                </div>
              </template>
              <template v-slot:cell(status)="row">
                <span
                  v-if="row.item.status === 3"
                  v-text="'Mới'"
                  class="
                    label
                    font-weight-bold
                    label-lg label-light-success label-inline
                  "
                ></span>
                <span
                  v-else
                  v-text="'Đã hủy'"
                  class="
                    label
                    font-weight-bold
                    label-lg label-light-danger label-inline
                  "
                ></span>
              </template>

              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="editItem(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon-eye"></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showCancelAlert(row.item)"
                      v-if="checkPermission('STOCK_UPDATE')"
                      v-show="row.item.status === 3"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="fas fa-ban"
                        ></i>
                        &nbsp; Hủy phiếu
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  v-model="page"
                  class="customPagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchStock"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
            <template>
              <b-modal
                v-model="showEditPriceModal"
                hide-footer
                :title="'Sửa ghi chú cho phiếu: ' + stockSlipCode"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label class="labelInput" for="inputOriginalPrice"
                      >Ghi chú</label
                    >
                    <b-form-textarea
                      id="textarea"
                      v-model="note"
                      placeholder="Nhập ghi chú..."
                      rows="5"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                      @click="updateNote"
                      >Lưu</b-button
                    >
                    <b-button
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                      @click="handleShowNoteModel"
                      >Hủy</b-button
                    >
                  </b-col>
                </b-row>
              </b-modal>
            </template>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import { VueAutosuggest } from 'vue-autosuggest';
import { BASE_URL, IMPORT_STOCK, TIME_TRIGGER } from '../../../utils/constants';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import decounce from 'debounce';
import localData from '../../../utils/saveDataToLocal';
import Multiselect from 'vue-multiselect';
import fileDownload from '../../../utils/file-download';
import axios from 'axios';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      btnCreate: {
        fontWeight: '600!important',
      },
      stocks: [],
      search: '',
      searchStock: '',
      searchId: '',
      currentPage: 0,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: IMPORT_STOCK,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'customerPhone',
          label: 'Khách hàng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      limit: 10,
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      optionsStore: [
        {
          data: [],
        },
      ],
      selectedCompany: null,
      listCompany: [],
      valueStore: [],
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      searchCustomer: '',
      inputSearchCustomerProp: {
        id: 'autosuggest__input',
        placeholder: 'SĐT Khách hàng',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      selectedCustomer: null,
      hoverPrice: false,
      stockSlipCode: '',
      showEditPriceModal: false,
      note: '',
      searchImeiCode: '',
      selectedStatus: null,
      listStatus: [
        {
          id: null,
          name: 'Tất cả trạng thái',
        },
        {
          id: 3,
          name: 'Mới',
        },
        {
          id: 4,
          name: 'Đã hủy',
        },
      ],
      listQuickFilterTime: [
          {
              value: 1,
              text: 'Vượt quá 24 tiếng'
          },
          {
              value: 2,
              text: 'Vượt quá 48 tiếng'
          }
      ],
      selectedQuickFilterTime: null,
      totalNewHoldingLast24Hours: 0,
      totalNewHoldingLast48Hours: 0,
      warningOverHoldingTime: false,
    };
  },
  methods: {
    onEndChange: function () {
      this.onFilter();
    },
    onStartChange: function () {
      this.onFilter();
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.setHeader();
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`
      ).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
          this.optionsStore.push(option);
        });
      });
    },
    async fetchStoreByUser() {
      const { data } = await ApiService.get(`stores/get-by-user`);
      if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
          let option = {
              code: element.id,
              name: element.shortName,
              checked: false,
          };
          this.optionsStore.push(option);
          if (!data.data.viewAll) {
              let index = this.optionsStore.findIndex(
              (item) => item.code == element.id
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
          }
          });
      }
      this.fetchStock();
    },
    fetchStock: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      if (this.searchCustomer.trim() === '') {
        this.selectedCustomer = null;
      }

      const params = {
        page: this.page,
        pageSize: 10,
        searchDesciption: this.search.trim(),
        searchCode: this.searchId.trim(),
        type: 17,
        searchProduct: this.searchProduct,
        listStoreId: listStoreId,
        selectedCustomer: this.selectedCustomer,
        searchImeiCode: this.searchImeiCode.trim(),
        status: this.selectedStatus,
      };

      if (this.selectedQuickFilterTime !== null) {
          params['quickFilter'] = this.selectedQuickFilterTime;
      } else {
          params['searchFromDay'] = this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '';
          params['searchToDay'] = this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '';
      }

      ApiService.query('stock-slips/holding-stocks', { params })
        .then((response) => {
          this.stocks = [];
          const stockResponse = response.data.data.result;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          let result = [];
          stockResponse.forEach((item, index) => {
            const stock = {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              code: item.code,
              sourceWarehouseId: item.sourceWarehouseId,
              providerId: item.providerId,
              description: item.description,
              totalAmount: item.totalAmount,
              status: item.status,
              type: item.type,
              createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
              createBy: item.createBy,
              customerName: item.customerName,
              customerPhone: item.customerPhone,
            };
            result.push(stock);
            this.stocks = [...new Set(result)];
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'info-holding-stock',
        query: { id: item.id },
      });
    },
    onSelected(option) {
      this.selected = option.item.name;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    showCancelAlert: function (item) {
      Swal.fire({
        title: 'Hủy phiếu giữ hàng!',
        text: 'Bạn có chắc muốn hủy phiếu giữ hàng này không?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Có',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.cancelItem(item);
        }
      });
    },
    cancelItem: async function (item) {
      ApiService.setHeader();
      ApiService.put(`stock-slips/cancel-holding/${item.id}`).then(
        ({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchStock();
          } else {
            this.makeToastFaile(data.message);
          }
        }
      );
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?searchProduct=${this.searchProduct}&storeId=&includeParent=true`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, TIME_TRIGGER),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-holding-stock',
      });
      this.fetchStock();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = true;
    },

    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = false;
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.setHeader();
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    fetchQuickFilter: function() { 
        const listStoreId = this.optionsStore.filter(item => item.checked).map(item => item.code);
        ApiService.get(`/stock-slips/quick_report?stores=${listStoreId}`)
            .then(response => {
                const { data: { data: { total24hours, total48hours }}} = response;
                this.totalNewHoldingLast24Hours = total24hours;
                this.totalNewHoldingLast48Hours = total48hours;
                this.warningOverHoldingTime = (total24hours === 0 && total48hours === 0) ? false : true;
            })
    },
    onChangeCompany() {
      this.valueStore = [];
      this.searchStock = '';
      this.fetchStore();
    },
    getLinkDoc(item) {
      return `#/holding-stock/info-holding-stock?id=${item.id}`;
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.phoneNo;
      this.selectedCustomer = option.item.id;
    },
    onInputChangCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [
        {
          data: this.optionsCustomer[0].data,
        },
      ];
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      this.debounceInputCustomer();
    },
    debounceInputCustomer: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    getListCustomer() {
      let text = this.searchCustomer;
      ApiService.setHeader();
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          const customers = data.data.data;
          this.listCustomer = customers;
          customers.map((element) => {
            let cus = {
              id: element.id,
              phoneNo: element.phoneNo,
              fullName: element.fullName,
              suggestionName: element.phoneNo + ' - ' + element.fullName,
            };
            this.optionsCustomer[0].data.push(cus);
          });
          this.filteredOptionsCustomer = [
            {
              data: this.optionsCustomer[0].data,
            },
          ];
        }
      );
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.stockSlipCode = item.code;
      this.note = item.description;
    },
    updateNote() {
      const data = {
        stockSlipCode: this.stockSlipCode,
        description: this.note,
      };

      ApiService.post('stocks/update-stockslip-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchStock();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    reportClick: async function () {
      if (!this.searchProvider || this.searchProvider.trim() === '') {
        this.selectedProvider = null;
      }

      if (!this.searchStock || this.searchStock.trim() === '') {
        this.selectedStoreId = null;
      }

      const listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });

      const params = {
        description: this.search ? this.search.trim() : '',
        code: this.searchId ? this.searchId.trim() : '',
        status: this.selectedStatus,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        storeIds: listStoreId,
        customerId: this.selectedCustomer,
        searchProduct: this.searchProduct,
        imeiCode: this.searchImeiCode,
      };

      if (!params.description) delete params.description;
      if (!params.code) delete params.code;
      if (!params.status) delete params.status;
      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;
      if (!params.storeIds) delete params.storeIds;
      if (!params.customerId) delete params.customerId;
      if (!params.searchProduct) delete params.searchProduct;
      if (!params.imeiCode) delete params.imeiCode;

      const url = `${BASE_URL}stock-slips/holding-stocks/excel`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params,
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    onQuickFilterChange(value) {
        if (value !== null) {
            this.selectedStatus = 3;
        } else {
            this.selectedStatus = null;
        }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu giữ hàng', route: 'holding-stock' },
      { title: 'Danh sách phiếu giữ hàng' },
    ]);
  },
  async created() {
    let code = this.$route.query.code;
    if (code !== undefined) {
      this.searchId = code;
    }
    this.fetchStore();
    this.fetchCompany();
    await this.fetchStoreByUser();
    this.fetchQuickFilter();
  },
  components: {
    KTCodePreview,
    VueAutosuggest,
    VclTable,
    datePicker,
    Multiselect,
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

/* h5 {
  font-size: 100%;
  padding: 0;
} */

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.productCode:hover {
  text-decoration: underline;
}
.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}
.multiselect {
  min-height: initial;
}
.multiselect__tags {
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 12px;
  min-height: initial;
}
.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}

.hold-report {
    font-size: 14px;
    font-weight: bold;
}
</style>
